import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

function RoomCard() {
    const [rooms, setRooms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch rooms data from the backend using axios
        const fetchRooms = async () => {
            try {
                const response = await axios.get('https://costels.com/admin_1/rooms/getrooms');
                if (response.data.status === 'success') {
                    setRooms(response.data.data || []); // Set rooms data
                } else {
                    throw new Error('Failed to fetch rooms data');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchRooms();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div className="text-danger">Error: {error}</div>;
    }

    return (
        <Fragment>
            <div className="row g-4">
                {rooms.map((room, index) => (
                    <div
                        key={room.id}
                        className="col-lg-4 col-md-6 wow fadeInUp"
                        data-wow-delay={`${0.1 * index}s`}
                    >
                        <div className="room-item shadow rounded overflow-hidden">
                            <div className="position-relative">
                                <img
                                    className="img-fluid"
                                    src={room.image}
                                    alt={room.name}
                                />
                                <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">
                                    ${room.price}/Night
                                </small>
                            </div>
                            <div className="p-4 mt-2">
                                <div className="d-flex justify-content-between mb-3">
                                    <h5 className="mb-0">{room.name}</h5>
                                </div>
                                <div className="d-flex mb-3">
                                    <small className="border-end me-3 pe-3">
                                        <i className="fa fa-bed text-primary me-2"></i>
                                        {room.beds} Bed
                                    </small>
                                    <small className="border-end me-3 pe-3">
                                        <i className="fa fa-bath text-primary me-2"></i>
                                        {room.bathrooms || 'N/A'} Bath
                                    </small>
                                    <small>
                                        <i className="fa fa-wifi text-primary me-2"></i>
                                        {room.amenities?.includes('Wifi')
                                            ? 'Wifi'
                                            : 'Wifi'}
                                    </small>
                                </div>
                                <p className="text-body mb-3">{room.description}</p>
                                <div className="d-flex justify-content-between">
                                    <Link
                                        className="btn btn-sm btn-primary rounded py-2 px-4"
                                        to={`/rooms/${room.id}`}
                                    >
                                        View Detail
                                    </Link>
                                    <Link
                                        className="btn btn-sm btn-dark rounded py-2 px-4"
                                        to={`/book/${room.id}`}
                                    >
                                        Book Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Fragment>
    );
}

export default RoomCard;
